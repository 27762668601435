import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './auth/login/Login';
import Auth from './auth/Auth';
import App from './App';
import ProtectedRoute from './util/ProtectedRoute';
import Home from './portal/home/Home';
import OSDetailsPage from './portal/detail-page/OSDetailsPage';
import IPDetailsPage from './portal/detail-page/IPDetailsPage';
import FCDetailsPage from './portal/detail-page/FCDetailsPage';
import DRDetailsPage from './portal/detail-page/DRDetailsPage';
import APTXDetailsPage from './portal/detail-page/APTXDetailsPage';
import CFDetailsPage from './portal/detail-page/CFDetailsPage';
import CODetailsPage from './portal/detail-page/CODetailsPage';
import PCDetailsPage from './portal/detail-page/PCDetailsPage';
import WFDetailsPage from './portal/detail-page/WFDetailsPage';
import PBDetailsPage from './portal/detail-page/PBDetailsPage';

import OSDetailsPageById from './portal/detail-page/OSDetailsPageById';
import DRDetailsPageById from './portal/detail-page/DRDetailsPageById';
import IPDetailsPageById from './portal/detail-page/IPDetailsPageById';
import FCDetailsPageById from './portal/detail-page/FCDetailsPageById';

// Common Component for both core and package
import ApexDetailsPage from './portal/detail-page/ApexDetailsPage';
import ComponentTemplatePackage from './portal/package-dashboard/packageHome';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter basename={'/'}>
			<Routes>
				<Route path='/auth' element={<Auth />}>
					<Route path='login' element={<Login />} />
				</Route>
				<Route path="/" element={<App />}>
					<Route path='' element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
				</Route>
				<Route path="/packageHome" element={<App />}>
					<Route path='' element={
						<ProtectedRoute>
							<ComponentTemplatePackage />
						</ProtectedRoute>
					} />
				</Route>
				<Route path="/details" element={<App />}>
					<Route path='OSDetails' element={
						<ProtectedRoute>
							<OSDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='IPDetails' element={
						<ProtectedRoute>
							<IPDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='FCDetails' element={
						<ProtectedRoute>
							<FCDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='APTXDetails' element={
						<ProtectedRoute>
							<APTXDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='CFDetails' element={
						<ProtectedRoute>
							<CFDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='CODetails' element={
						<ProtectedRoute>
							<CODetailsPage />
						</ProtectedRoute>
					} />
					<Route path='PCDetails' element={
						<ProtectedRoute>
							<PCDetailsPage />
						</ProtectedRoute>
					} />
					<Route path='WFDetails' element={
						<ProtectedRoute>
							<WFDetailsPage />
						</ProtectedRoute>
					} />	
					<Route path='PBDetails' element={
						<ProtectedRoute>
							<PBDetailsPage />
						</ProtectedRoute>
					} />
																													
					<Route path='DRDetails' element={
						<ProtectedRoute>
							<DRDetailsPage />
						</ProtectedRoute>
					} />	
					<Route path='OSDetailsById' element={
						<ProtectedRoute>
							<OSDetailsPageById />
						</ProtectedRoute>
					} />
					<Route path='FCDetailsById' element={
						<ProtectedRoute>
							<FCDetailsPageById />
						</ProtectedRoute>
					} />	
					<Route path='DRDetailsById' element={
						<ProtectedRoute>
							<DRDetailsPageById />
						</ProtectedRoute>
					} />	
					<Route path='IPDetailsById' element={
						<ProtectedRoute>
							<IPDetailsPageById />
						</ProtectedRoute>
					} />	
					<Route path='ApexDetails' element={
						<ProtectedRoute>
							<ApexDetailsPage />
						</ProtectedRoute>
					} />																																				
				</Route>				
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
