import React, {useEffect, useState, useContext} from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import {useLocation} from 'react-router-dom';
import ComponentTemplate from '../core-dashboard/coreOsHome';
import ComponentTemplatePackage from '../package-dashboard/packageHome';
import SFCoreComponents from '../sf-core/sfCoreHome';
import SFStaticComponents from '../sf-core/sfStaticComponents';


const Home = () => {
	const { state } = useLocation();
	const [corePackageSelected, setCorePackageSelected] = useState();
	const [nameSpace, setNameSpace] = useState();

	const currenPackageDefined = localStorage.getItem('currentPackage');
	const currentNamespaceDefined = localStorage.getItem('nameSpace');

	useEffect( () => {
		const currentPackage = currenPackageDefined
		//const userSelectedPacakge = {"package":state}
		if(currentPackage=== 'package') {
			setCorePackageSelected(false)
			setNameSpace(currentNamespaceDefined)
		} else{
			setCorePackageSelected(true)
		}
	}, []);
	const componentToRender = [
		{
			id: 1,
			title: "Omniscript",
			abbr: "OS",
			bestPractLabel:"Elements <200",
			badPractLabel:"Elements >200"
		},
		{
			id: 2,
			title: "Integration Procedure",
			abbr: "IP",
			bestPractLabel:"JSON Trimmed",
			badPractLabel:"JSON Not Trimmed"
		},
		{
			id: 3,
			title: "Dataraptor",
			abbr: "DR",
			bestPractLabel:"Following best practices",
			badPractLabel:"Not Following best practices"
		},
		{
			id: 4,
			title: "Flexcard",
			abbr: "FC",
			bestPractLabel:"Following best practices",
			badPractLabel:"Not Following best practices"
		},
		{
			id: 5,
			title: "Apex",
			abbr: "Apex",
			bestPractLabel:"Code < 1000 lines",
			badPractLabel:"Code > 1000 lines"
		}	
	]
	const sfCoreComponentsToRender = [
		{
			id: 1,
			title: "Apex Triggers",
			abbr: "APTX",
			bestPractLabel:"Following best Practice",
			badPractLabel:"Not Following best Practice"			
		},
		{
			id: 2,
			title: "Custom Fields",
			abbr: "CF",
			bestPractLabel:"Fields <30",
			badPractLabel:"Fields >30"			
		},
		{
			id: 3,
			title: "Custom Objects",
			abbr: "CO",
			bestPractLabel:"",
			badPractLabel:""	
		},
		{
			id: 4,
			title: "Platform Cache",
			abbr: "PC",
			bestPractLabel:"Following Best Practices",
			badPractLabel:"Not Following Best Practices"			
		},
		{
			id: 5,
			title: "Work Flow Rules",
			abbr: "WF",
			bestPractLabel:"",
			badPractLabel:""			
		},
		{
			id: 6,
			title: "Process Builder",
			abbr: "PB",
			bestPractLabel:"",
			badPractLabel:""			
		}			
	]	
	const sfStaticLimits = [
		{
			id: 1,
			title: "Static Resources",
			abbr: "SR",
		},
		{
			id: 2,
			title: "File Storage",
			abbr: "FS",
		},
		{
			id: 3,
			title: "Data Storage",
			abbr: "ST",
		},
		{
			id: 4,
			title: "Apex Code Coverage",
			abbr: "APTXCDECVRG",
		}		

	]		
	return (
		<React.Fragment>
			<Container className='py-5'>
				<h3 className='fw-normal'>Welcome Home. {corePackageSelected}</h3>
			<Row>
				{!corePackageSelected && componentToRender.map(element => {return<ComponentTemplatePackage element={element} key={element.id} nameSpace={nameSpace}/>
				})}
				{corePackageSelected && componentToRender.map(element => {return <ComponentTemplate element={element} key={element.id}/> })}
			
				{sfCoreComponentsToRender.map(element => {return <SFCoreComponents element={element} key={element.id}/> })}

				{sfStaticLimits.map(element => {return <SFStaticComponents element={element} key={element.id}/> })}
			
			</Row>
			</Container>
		</React.Fragment>
	)
}

export default Home;