import React, {useState, useEffect}from "react";
import {useLocation} from 'react-router-dom';
import {getCoreOSById, getPackageOSById} from '../../services/getAllCoreOS';
import { Container } from 'react-bootstrap';
import DetailPageTemplateById from './detailPageTemplateById';

const OSDetailsPageById = () => {
	const { state } = useLocation();
	const [tableHeader, setTableHeader] = useState([]);
	const [pageData, setPageData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect( () => {
		const options = {"id":state.Id, "namespace":localStorage.getItem('nameSpace')}

		const result = localStorage.getItem('nameSpace').length>0?getPackageOSById(options):getCoreOSById(options)

		setLoading(true);
		result.then(data => {
			console.log("API Data", data);
			setLoading(false)
			setTableHeader([])
			setPageData(prevObject => ({
				...prevObject,
				...data
			  }));
			Object.entries(data.records[0]).forEach(([key, value]) => {		
				if(key.trim() !== "attributes" && key.trim() !== "ElementTypeComponentMapping"){
					setTableHeader((prevProducts) => [ ...prevProducts, key])
				}
			});			
		})
	}, [state]);	

    return (
		<Container fluid>
			<h1 className="py-3">Omniscript by Id </h1>
			<DetailPageTemplateById tableHeader={tableHeader} pageData = {pageData} />
        </Container>
    );
}

export default OSDetailsPageById;