import React from 'react';
import { Container,Table } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner'
import './detailPageTemplate.css';

import TableRow from './TableRow';
const DetailPageTemplate = (props) => {
	
	return (
        <React.Fragment>
            {!props.isLoading && <Container className="pb-5 mb-5 table-container" fluid>
					<Table className="p-5" striped bordered hover>
						<thead>
							<tr>
								{props.tableHeader.map(element => {return <th key={element}>{element}</th> })}
                                {props.showDetails === "true" && <th></th>}
							</tr>
						</thead>
						<tbody>
							{props.state.records.map((element,index) => {
								return <TableRow rows={element} cols={props.tableHeader} key={index} showDetails = {props.showDetails}/>
							})}
						
						</tbody>
					</Table>
			</Container>

            }

            {props.isLoading && <Oval
                height={80}
                width={80}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
            }            

        </React.Fragment>
	)
}

export default DetailPageTemplate;