import React, {useState, useEffect}from "react";
import {useLocation} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import DetailPageTemplate from './detailPageTemplate';

const OSDetailsPage = () => {
	const { state } = useLocation();
	const [tableHeader, setTableHeader] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
	const [showDetails, setShowDetails] = useState("true")
	//const [tableBody, setTableBody] = useState([])


	useEffect(() => {
		setIsLoading(true);
		setTableHeader(tokenArray => []);
		Object.entries(state.records[0]).forEach(([key, value]) => {		
			if(key.trim() !== "attributes" && key.trim() !== "ElementTypeComponentMapping"){
				setTableHeader((prevProducts) => [ ...prevProducts, key])
			}

		});
		setIsLoading(false);
	}, [state.records[0]])


    return (
		<Container fluid >
			<h1 className="py-3">Omniscript List </h1>
            <DetailPageTemplate tableHeader={tableHeader} state={state} isLoading={isLoading} showDetails={showDetails}/>
        </Container>
    );
}

export default OSDetailsPage;