import axios from "axios"

export async function getAllApex(page, options) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getApex";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  }).catch(function (error) {
		if (error.response) {
		  // The request was made and the server responded with a status code
		  // that falls out of the range of 2xx
		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);
		} else if (error.request) {
		  // The request was made but no response was received
		  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		  // http.ClientRequest in node.js
		  console.log(error.request);
		} else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}
		console.log(error.config);
	  });
	  return data;
}