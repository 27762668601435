
import axios from "axios"

export async function getCoreIP(page, options) {

	const ENDPOINT = process.env.REACT_APP_API_URL+"getIntegrationProcedure";
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageIP(namespace) {

	const ENDPOINT = process.env.REACT_APP_API_URL+"getIntegrationProcedurePackage/"+namespace;
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getCoreIPById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getIntegrationProcedure/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageIPById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getIntegrationProcedurePackage/"+options.namespace+"/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}