import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import '../../styles/dashboard.css';

import {getAllLimits, getSRLimit, getApexCodeCoverage} from '../../services/getAllLimits'

const SFStaticComponents = (props) => {

	const navigate = useNavigate();

	const  [noOfElements, setNoOfElements] = useState(0);
	const  [elementData, setElementData] = useState();
	const  [currentStorage, setCurrentStorage] = useState(0);
	const  [maxStorage, setMaxStorage] = useState(0);
	const  [showMaxStorage, setShowMaxStorage] = useState();
	const  [showStorage, setShowStorage] = useState();	
	const  [meetsCodeCoverage, setmeetsCodeCoverage] = useState();
	const  [usagePercent, setUsagePercent] = useState(0);
	useEffect( () => {
		const result = props.element.title === 'Static Resources'?getSRLimit():props.element.title === 'Apex Code Coverage'?getApexCodeCoverage():getAllLimits()

		if (result !== null) {
			result.then(data => {
				console.log("API Data",data);
				setNoOfElements(Object.keys(data).length)

				if(props.element.title === 'File Storage'){
					let currenStorage = parseInt(data['FileStorageMB']['Max']) - parseInt(data['FileStorageMB']['Remaining'])
					setUsagePercent((currenStorage*100)/parseInt(data['FileStorageMB']['Max']));
					setCurrentStorage(currenStorage)
					setShowMaxStorage(true)
					setShowStorage(true)
					setMaxStorage(data['FileStorageMB']['Max'])
				} else if(props.element.title === 'Data Storage'){
					let currenStorage = parseInt(data['DataStorageMB']['Max']) - parseInt(data['DataStorageMB']['Remaining'])
					setUsagePercent((currenStorage*100)/parseInt(data['DataStorageMB']['Max']));
					//console.log("(currenStorage*100)/parseInt(data['DataStorageMB']['Remaining'])", (parseInt(data['DataStorageMB']['Max']) - parseInt(data['DataStorageMB']['Remaining']))+"parseInt(data['DataStorageMB']['Remaining'])::"+parseInt(data['DataStorageMB']['Remaining']))
					setCurrentStorage(currenStorage)
					setShowMaxStorage(true)
					setShowStorage(true)
					setMaxStorage(data['DataStorageMB']['Max'])
				} else if (props.element.title === "Static Resources"){
					setShowMaxStorage(false)
					setShowStorage(true)
					let currenStorage = parseInt(data['records'][0]['expr0']/1000000)
					setCurrentStorage(currenStorage)
				} else if(props.element.title === "Apex Code Coverage"){
					setShowStorage(false)
					let currenStorage = parseInt(data['records'][0]['PercentCovered'])
					if(currenStorage>75){
						setmeetsCodeCoverage(true)
					}else{
						setmeetsCodeCoverage(false)
					}
					setCurrentStorage(currenStorage)					

				}
				setElementData(data);
			}).catch(function (error) {
				if (error.response) {
				  // The request was made and the server responded with a status code
				  // that falls out of the range of 2xx
				  console.log(error.response.data);
				  console.log(error.response.status);
				  if(error.response.status === 401){
					localStorage.clear();
					navigate('/auth/login');				
				  }
				  console.log(error.response.headers);
				} else if (error.request) {
				  // The request was made but no response was received
				  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				  // http.ClientRequest in node.js
				  console.log(error.request);
				} else {
				  // Something happened in setting up the request that triggered an Error
				  console.log('Error', error.message);
				}
				console.log(error.config);
			  });
		}				

	}, [props.element.title]);
	const navigateToDetailsPage = (title, elementData) => {
		const navigateURL = "/details/"+title+"Details"
		navigate(navigateURL, { state: elementData })
	}
	return (
        <Col xs={12} md={4}>
			<Container className='py-5'>
				{noOfElements > 0 && <Card className="text-center main-card-container">
					<Card.Header className="card-body-header">{props.element.title}</Card.Header>
					<Card.Body>
						<div className="card-body-best-pract" >
							{showStorage && <div><span className='bp-parent-style'>Current Storage</span> <span className='bp-child-style'> {currentStorage} MB</span> </div>}
							{showMaxStorage && <div><span className='bp-parent-style mt-3'>Max Storage</span> <span className='bp-child-style'>{maxStorage} MB</span></div>}	
							{(showMaxStorage && usagePercent<=70) && <div><span className='bp-parent-style good-prac mt-3'>Usage less than 70%</span></div>}
							{(showMaxStorage && (usagePercent>70 && usagePercent<=90)) && <div><span className='bp-parent-style ok-prac mt-3'>Usage greater than 70% but less than 90%</span></div>}	
							{(showMaxStorage && (usagePercent>90)) && <div><span className='bp-parent-style bad-prac mt-3'>Usage greater than 90</span></div>}	
							{!showStorage && 
								<div>
									<span className='bp-parent-style'>Code Coverage</span> <span className='bp-child-style'>{currentStorage}%</span>
									<span className='bp-parent-style mt-3'>You must have at least 75% code coverage </span>
								</div>
							}	
							{(!showStorage && meetsCodeCoverage) && 
								<div>
									<span className='bp-parent-style good-prac mt-2'>Code Coverage expectations met </span>
								</div>
							}
							{(!showStorage && !meetsCodeCoverage) && 
								<div>
									<span className='bp-parent-style bad-prac mt-2'>Doesn't meet code coverage expectations </span>
								</div>
							}																				
						</div>
					</Card.Body>
				</Card>}


				{noOfElements === 0 && <Oval
					height={80}
					width={80}
					color="#4fa94d"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#4fa94d"
					strokeWidth={2}
					strokeWidthSecondary={2}

				/>
				}

		</Container>
		</Col>		
	)
}

export default SFStaticComponents;