import React, {useState, useEffect}from "react";
import {useLocation} from 'react-router-dom';
import DetailPageTemplate from './detailPageTemplate';


const APTXDetailsPage = () => {
	const { state } = useLocation();
	const [tableHeader, setTableHeader] = useState([]);
    const [isLoading, setIsLoading] = useState(false)


	useEffect(() => {
        setIsLoading(true);
		setTableHeader(tokenArray => []);
		Object.entries(state.records[0]).forEach(([key, value]) => {		
			if(key.trim() !== "attributes" && key.trim() !== "ElementTypeComponentMapping"){
				setTableHeader((prevProducts) => [ ...prevProducts, key])
			}

		}
		);
        setIsLoading(false);
	}, [state.records[0]])    
    return (
        <React.Fragment>
            <h1>Apex Trigger Page</h1>
            <DetailPageTemplate tableHeader={tableHeader} state={state} isLoading={isLoading} />
        </React.Fragment>

    );
}

export default APTXDetailsPage;