
import axios from "axios"

export async function getCoreOS(page, options) {
	console.log("here in core OS")
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getOmniScript";
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageOS(namespace) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getOmniScriptPackage/"+namespace;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}

export async function getCoreOSById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getOmniScript/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageOSById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getOmniScriptPackage/"+options.namespace+"/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}