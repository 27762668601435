import React, {useState, useEffect}from "react";
import {useLocation} from 'react-router-dom';
import {getCoreIPById, getPackageIPById} from '../../services/getAllCoreIP';
import { Container } from 'react-bootstrap';
import DetailPageTemplateById from './detailPageTemplateById';

const IPDetailsPageById = () => {
	const { state } = useLocation();
	const [tableHeader, setTableHeader] = useState([]);
	const [pageData, setPageData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect( () => {
		const options = {"id":state.Id, "namespace":localStorage.getItem('nameSpace')}

		const result = localStorage.getItem('nameSpace').length>0?getPackageIPById(options):getCoreIPById(options)
		setLoading(true);
		result.then(data => {
			console.log("API Data", data);
			setLoading(false)
			setTableHeader([])
			setPageData(prevObject => ({
				...prevObject,
				...data
			  }));
			Object.entries(data.records[0]).forEach(([key, value]) => {		
				if(key.trim() !== "attributes" && key.trim() !== "ElementTypeComponentMapping"){
					setTableHeader((prevProducts) => [ ...prevProducts, key])
				}
			});			
		})
	}, [state]);	

    return (
		<Container fluid>
		<h1  className="py-3">Integration Procedure List by ID</h1>
			<DetailPageTemplateById tableHeader={tableHeader} pageData = {pageData} />
        </Container>
    );
}

export default IPDetailsPageById;