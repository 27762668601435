import axios from "axios"

export async function getAllLimits(page, options) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getDataStorage/limits";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}

export async function getSRLimit(page, options) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getStaticResource";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}

export async function getApexCodeCoverage(page, options) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getApexCoverage";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}