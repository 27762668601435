import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { Oval } from 'react-loader-spinner'
import './detailPageTemplate.css';

import TableRowById from './TableRowById';
const DetailPageTemplateById = (props) => {

    return (
        <React.Fragment>

            {props.pageData.records !== undefined && <Container className="pb-5 mb-5 table-container" fluid>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {props.tableHeader.map((element, index) => { return <th key={index}>{element}</th> })}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {props.pageData.records.map((element, index) => {
                            return <TableRowById rows={element} key={index} />
                        })}

                    </tbody>
                </Table>
            </Container>}

            {props.pageData.records === undefined &&

                <Container>
                    <Oval
                        height={80}
                        width={80}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}

                    />
                </Container>
            }

        </React.Fragment>
    )
}

export default DetailPageTemplateById;