
import axios from "axios"

export async function getCoreFC(page, options) {

	const ENDPOINT = process.env.REACT_APP_API_URL+"getflexcard";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageFC(namespace) {

	const ENDPOINT = process.env.REACT_APP_API_URL+"getflexcardPackage/"+namespace;
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}


export async function getCoreFCById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getflexcard/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}

export async function getPackageFCById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getflexcardPackage/"+options.namespace+"/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}