
import axios from "axios"

export async function getCoreDR(page, options) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getdataRaptor";
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}
export async function getPackageDR(namespace) {
	const ENDPOINT = process.env.REACT_APP_API_URL+"getdataRaptorPackage/"+namespace;
	const token = localStorage.getItem('user-token')
	const authToken = "Bearer "+token;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}

export async function getCoreDRById(options) {
	const token = localStorage.getItem('user-token');
	const authToken = "Bearer "+token;
	const ENDPOINT = process.env.REACT_APP_API_URL+"getdataRaptor/"+options.id;
	const { data } = await axios.get(ENDPOINT, {
		params: "",
		signal: "",
		headers: { Authorization: authToken, 'Access-Control-Allow-Origin': '*' },
	  });
	  return data;
}