import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const AuthNavbar = () => {
    return (
        <Navbar bg="dark" expand="lg" className="navbar-dark">
            <Container>
                <Navbar.Brand>COAT&nbsp;(Comprehensive org Assesment) App</Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default AuthNavbar;