import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import '../../styles/dashboard.css';

import {getPackageOS} from '../../services/getAllCoreOS'
import {getPackageIP} from '../../services/getAllCoreIP'
import {getPackageFC} from '../../services/getAllCoreFC'
import {getPackageDR} from '../../services/getAllCoreDR'


import {getAllApex} from '../../services/getAllApex'

const ComponentTemplatePackage = (props) => {

	const navigate = useNavigate();

	console.log("in package jsx", props.nameSpace )

	const  [noOfElements, setNoOfElements] = useState(0);
	const  [elementData, setElementData] = useState();
	const  [showBestPracCount, setShowBestPracCount] = useState(true);
	const  [noOfBadElements, setNoOfBadElements] = useState(0);
	const  [noOfGoodElements, setNoOfGoodElements] = useState(0);
	useEffect( () => {

		if(props.nameSpace !== undefined){
			const result = props.element.abbr === 'OS'?getPackageOS(props.nameSpace):props.element.abbr === 'FC'
			?getPackageFC(props.nameSpace):props.element.abbr === 'IP'?getPackageIP(props.nameSpace):props.element.abbr === 'DR'
			?getPackageDR(props.nameSpace):props.element.abbr === 'Apex'?getAllApex():null
			result.then(data => {
			console.log("API Data", data);
			console.log("props.element.title", props.element.title);
			if(props.element.title === 'Flexcard'){
				setShowBestPracCount(false)
			}
			setNoOfElements(data.totalSize)
			setElementData(data)
			setNoOfGoodElements(data['Elements following good prac'])
			setNoOfBadElements(data['Elements following bad prac']);
			}).catch(function (error) {
			if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response.data);
			console.log(error.response.status);
			if(error.response.status === 401){
				localStorage.clear();
				navigate('/auth/login');				
			}
			console.log(error.response.headers);
			} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
			} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			}
			console.log(error.config);
			});
		}

	}, [props.nameSpace]);
	const navigateToDetailsPage = (title, elementData) => {
		const navigateURL = "/details/"+title+"Details"
		navigate(navigateURL, { state: elementData })
	}
	return (
        <Col xs={12} md={4}>
			<Container className='py-5'>
				{noOfElements > 0 && <Card className="text-center main-card-container">
					<Card.Header className="card-body-header">{props.element.title}</Card.Header>
					<Card.Body>
						<Card.Text className="card-body-count" >
							{noOfElements}
						</Card.Text>
						{showBestPracCount &&<div className="card-body-best-pract" ><Card.Text >
							<span className='bp-parent-style'>{props.element.bestPractLabel}</span><span className='bp-child-style good-prac'>{noOfGoodElements}</span>
						</Card.Text>	
						<Card.Text>
						<span className='bp-parent-style'>{props.element.badPractLabel}</span><span className='bp-child-style bad-prac' >{noOfBadElements}</span>
						</Card.Text>	
						</div>}
									
						<Button variant="primary" onClick={() => navigateToDetailsPage(props.element.abbr, elementData)}>Details</Button>
					</Card.Body>
				</Card>}


				{noOfElements === 0 && <Oval
					height={80}
					width={80}
					color="#4fa94d"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#4fa94d"
					strokeWidth={2}
					strokeWidthSecondary={2}

				/>
				}

		</Container>
		</Col>		
	)
}

export default ComponentTemplatePackage;