import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import '../../styles/dashboard.css';
import {getAllApexTriggers} from '../../services/getAllApexTriggers'
import {getAllCustomFields} from '../../services/getAllCustomFields'
import {getAllCustomObjects} from '../../services/getAllCustomObjects'
import {getAllWorkFlowRules} from '../../services/getAllWorkFlowRules'
import {getAllProcessBuilder} from '../../services/getAllProcessBuilder'
import {getAllPlatformCache} from '../../services/getAllPlatformCache'

const SFCoreComponents = (props) => {

	const navigate = useNavigate();

	const  [noOfElements, setNoOfElements] = useState(0);
	const  [elementData, setElementData] = useState();
	const  [noOfBadElements, setNoOfBadElements] = useState(0);
	const  [showBestPracCount, setShowBestPracCount] = useState(false);
	const  [noOfGoodElements, setNoOfGoodElements] = useState(0);	
	useEffect( () => {
		const result = props.element.title === 'Apex Triggers'?getAllApexTriggers():props.element.title === 'Custom Fields'
						?getAllCustomFields():props.element.title === 'Custom Objects'?getAllCustomObjects():props.element.title === 'Platform Cache'
						?getAllPlatformCache():props.element.title === 'Work Flow Rules'
						?getAllWorkFlowRules():props.element.title === 'Process Builder'?getAllProcessBuilder():null

		if (result !== null) {
			result.then(data => {
				console.log("API Data", data);
				setNoOfElements(data.totalSize);
				setElementData(data);
				if(props.element.title === 'Custom Fields' || props.element.title === 'Platform Cache' || props.element.title === 'Apex Triggers' ){
					setShowBestPracCount(true)
				}				
				setNoOfGoodElements(data['Elements following good prac'])
				setNoOfBadElements(data['Elements following bad prac']);				
			})
		}				

	}, [props.element.title]);
	const navigateToDetailsPage = (title, elementData) => {
		const navigateURL = "/details/"+title+"Details"
		navigate(navigateURL, { state: elementData })
	}
	return (
        <Col xs={12} md={4}>
			<Container className='py-5'>
				{noOfElements > 0 && <Card className="text-center main-card-container">
					<Card.Header className="card-body-header">{props.element.title}</Card.Header>
					<Card.Body>
						<Card.Text className="card-body-count" >
							{noOfElements}
						</Card.Text>
						{showBestPracCount &&<div className="card-body-best-pract" ><Card.Text >
							<span className='bp-parent-style'>{props.element.bestPractLabel}</span><span className='bp-child-style good-prac'>{noOfGoodElements}</span>
						</Card.Text>	
						<Card.Text>
						<span className='bp-parent-style'>{props.element.badPractLabel}</span><span className='bp-child-style bad-prac' >{noOfBadElements}</span>
						</Card.Text>	
						</div>}						
						<Button variant="primary" onClick={() => navigateToDetailsPage(props.element.abbr, elementData)}>Details</Button>
					</Card.Body>
				</Card>}

				{noOfElements === 0 && <Oval
					height={80}
					width={80}
					color="#4fa94d"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel='oval-loading'
					secondaryColor="#4fa94d"
					strokeWidth={2}
					strokeWidthSecondary={2}

				/>
				}

		</Container>
		</Col>		
	)
}

export default SFCoreComponents;