import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



const TableRow = (props) => {

	const navigate = useNavigate();

	const rowDataArray = Object.entries(props.rows);
	const filteredArray = rowDataArray.filter(num => (num[0] !==  'attributes' &&  num[0] !== "ElementTypeComponentMapping")).map(num => num[1]);
	
	//console.log("props.rows", props.rows);

	const navigateToDetailsPage = (element) => {
		console.log("ElementID:", element);

		const title = element.attributes.type === "OmniUiCard"? 'FC':
						element.attributes.type === "OmniProcess" && element.Language !== "Procedure"? "OS":
						element.attributes.type === "OmniProcess" && element.Language === "Procedure"? "IP":
						element.attributes.type === "Package_IntegrationProcedure"? "IP":
						element.attributes.type === "OmniProcessPackage"? "OS": "DR"
		const navigateURL = "/details/"+title+"DetailsById"

		console.log("navigateURL:"+navigateURL);
		navigate(navigateURL, { state: element })
		
	}

    return (
        <tr>
			{ filteredArray.map((element,index) => {return <td key={index}>{element === true?"True":element === false? "False": element}</td> })} 
			{props.showDetails === "true" && <td> <Button variant="link" onClick={() => navigateToDetailsPage(props.rows)}>Details</Button></td>}
			
		</tr>
    );
}

export default TableRow;