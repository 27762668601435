import React, {useState, useContext} from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
//import https from 'https';


async function loginUser(credentials) {
    const END_POINT = process.env.REACT_APP_API_URL + "login";
    console.log("API URL", END_POINT);
    //const sslConfiguredAgent = new https.Agent();

    return fetch(END_POINT, {
        method: 'POST',
        //agent: sslConfiguredAgent,
        headers: {
            'Content-Type': 'application/json',
            referrerPolicy: "unsafe-url", // no-referrer-when-downgrade, no-referrer, origin, same-origin...
            mode: "no-cors", // same-origin, no-cors
            credentials: "same-origin", // omit, include
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
    .catch(error => {
        console.error("Error: ", error);
    })
}

const Login = () => {



    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [securityToken, setSecurityToken] = useState();
    const [domain, setDomainType] = useState();
    const [loginType, setloginType] = useState();
    const [customerName, setCustomerName] = useState();
    const [packageSelected, setPackageSelected] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [SecurityLabel, setSecurityLabel] = useState('Organization Id');
    const [selectedNameSpace, setSelectedNameSpace] = useState('cmt');
    const [showNameSpaceSelection, setShowNameSpaceSelection] = useState(false);
    const [showCustomDomain, setShowCustomDomain] = useState(false);
    const [showSecurityTokenOption, setShowSecurityTokenOption] = useState(false);
    const [useSecurityToken, setUseSecurityToken] = useState();


    //const loginAPI = 'https://tararoutray.com/demo/react-auth/login.php';
    const navigate = useNavigate();

    const submitLoginForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        const token = await loginUser({
            email,
            password,
            securityToken,
            domain,
            customerName,
            useSecurityToken
        });
        console.log("token", token)
        if (token !== undefined) {
            setIsLoading(false)
            localStorage.clear();
            localStorage.setItem('user-token', token.access_token);
            localStorage.setItem('currentPackage', packageSelected);
            localStorage.setItem('nameSpace', selectedNameSpace);
            setTimeout(() => {
                navigate('/', { state: { packageSelected: packageSelected, nameSpace: selectedNameSpace } });
            }, 500);
        } else {
            setTimeout(() => {
                setIsLoading(false)
            }, 500);            
        }

    }
    const setSelectedRuntime = (event) => {
        setShowNameSpaceSelection(false)
        setPackageSelected(event.target.value)
        if (event.target.value === 'package') {
            setShowNameSpaceSelection(true)
        }
    }
    const setDomain = (event) => {
        setShowCustomDomain(false)
        setDomainType(event.target.value)
        if (event.target.value === 'custom') {
            setShowCustomDomain(true)
        }
    } 
    const securityTokeOption = (event) => {
        setShowSecurityTokenOption(true)
        setloginType(event.target.value)
        if (event.target.value === 'secToken') {
            setSecurityLabel("Security Token")
            setUseSecurityToken((current) => {return true});
        } else {
            setSecurityLabel("Organization Id")
            setUseSecurityToken((current) => {return false});
        }
        console.log("useSecurityToken", useSecurityToken)
    } 

    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login</h2>
                <Row>
                    <Col md={{ span: 12 }}>
                        <Form id="loginForm" onSubmit={submitLoginForm}>
                            <Row className="mb-3">
                                <FormGroup className="mb-3" as={Col} >
                                    <FormLabel htmlFor={'login-username'}>Username</FormLabel>
                                    <input type={'text'} className="form-control" id={'login-username'} name="username" required onChange={e => setEmail(e.target.value)} />
                                </FormGroup>
                                <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                    <input type={'password'} className="form-control" id={'login-password'} name="password" required onChange={e => setPassword(e.target.value)} />
                                </FormGroup>
                            </Row>
                            <Row className="mb-3">
                                <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'loginType'}>Login with</FormLabel>
                                    {/* <input type={'text'} className="form-control" id={'Domain'} name="password" onChange={e => setDomainType(e.target.value)} /> */}
                                    <Form.Select value={loginType} defaultValue={"default"} aria-label="Default select example" onChange={securityTokeOption} required>
                                        <option value={"default"} disabled>
                                            Choose an option
                                        </option>
                                        <option value="orgId">Organization ID</option>
                                        <option value="secToken">Security Token</option>
                                    </Form.Select>
                                </FormGroup>    
                                {showSecurityTokenOption && <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'security-key'}>{SecurityLabel}</FormLabel>
                                    <input type={'password'} className="form-control" id={'security-key'} name="security-key" required onChange={e => setSecurityToken(e.target.value)} />
                                </FormGroup>}                                                            
                                <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'Domain'}>Domain</FormLabel>
                                    {/* <input type={'text'} className="form-control" id={'Domain'} name="password" onChange={e => setDomainType(e.target.value)} /> */}
                                    <Form.Select value={domain} defaultValue={"default"} aria-label="Default select example" onChange={setDomain} required>
                                        <option value={"default"} disabled>
                                            Choose an option
                                        </option>
                                        <option value="test">Sandbox</option>
                                        <option value="custom">Custom</option>
                                    </Form.Select>
                                </FormGroup>
                                {showCustomDomain &&

                                    <FormGroup className="mb-3" as={Col}>
                                        <FormLabel htmlFor={'Domain'}>Custom Domain</FormLabel>
                                        <input type={'text'} className="form-control" id={'Domain'} name="password" required onChange={e => setDomainType(e.target.value)} />  </FormGroup>}
                            </Row>
                            <Row className="mb-3">
                                <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'customer'}>Customer</FormLabel>
                                    <input type={'text'} className="form-control" id={'customer'} name="customer" onChange={e => setCustomerName(e.target.value)} />
                                </FormGroup>
                                <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'customer'}>Runtime</FormLabel>
                                    <Form.Select aria-label="Default select example" onChange={setSelectedRuntime}>
                                        <option value="core">Core</option>
                                        <option value="package">Package</option>
                                    </Form.Select>
                                </FormGroup>
                                {showNameSpaceSelection && <FormGroup className="mb-3" as={Col}>
                                    <FormLabel htmlFor={'customer'}>Select Package namespace</FormLabel>
                                    <Form.Select aria-label="Default select example" onChange={e => setSelectedNameSpace(e.target.value)}>
                                        <option value="cmt">CME</option>
                                        <option value="ins">INS</option>
                                        <option value="ps">PS</option>
                                    </Form.Select>
                                </FormGroup>}
                            </Row>
                            {!isLoading && <Button variant="primary" type="submit" className="btn-success mt-2" id="login-btn" >
                                Submit
                            </Button>}
                            {isLoading && <Button variant="primary" type="submit" className="btn-success mt-2" id="login-btn" >
                                <Oval
                                    height={30}
                                    width={30}
                                    color="#4fa94d"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#4fa94d"
                                    strokeWidth={2}
                                    strokeWidthSecondary={2}

                                />
                            </Button>}
                        </Form>                        
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default Login;