import React from "react";
const TableRowById = (props) => {
	const rowDataArray = Object.entries(props.rows);
	const filteredArray = rowDataArray.filter(num => (num[0] !==  'attributes' &&  num[0] !== "ElementTypeComponentMapping")).map(num => num[1]);

    return (
        <tr>
			{ filteredArray.map((element,index) => {return <td key={index}>{element === true?"True":element === false? "False": element}</td> })} 
			
		</tr>
    );
}

export default TableRowById;